import * as React from "react"
import { useState, useEffect } from "react"
import NavBar from "../components/navbar"
import NavBarMobile from "../components/navbar-mobile"

const Header = () => {
    const [menu, showMenu] = useState(true)

    const toggleMenu = () => {
      let distanceFromTop = window.pageYOffset
  
      return function () {
        let newDistanceFromTop = window.pageYOffset
        if (newDistanceFromTop < 50 || distanceFromTop >= newDistanceFromTop) {
          showMenu(true)
        } else {
          showMenu(false)
        }
        distanceFromTop = newDistanceFromTop
      }
    }
  
    useEffect(() => {
      window.addEventListener("scroll", toggleMenu())
      return window.removeEventListener("scroll", toggleMenu())
    }, [])
  
    return (

        <header>
          <div className={menu ? "header showUp mobile-hide" : "header hideUP mobile-hide"}>
             <NavBar/>
          </div>
          <div className={"mobile-show header"}>
             <NavBarMobile/>
          </div>  
        </header>
    )
}

export default Header