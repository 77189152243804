import React, {useState, encode, navigate} from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import PageTransition from 'gatsby-plugin-page-transitions'

import Header from "../components/header"
import Footer from "../components/footer"

import Section from "../components/section"
import Container from "../components/container"
import Row from "../components/row"
import Col from "../components/col"

import "../static/assets/css/style.css"
import "../static/assets/fonts/icons/remixicon.css"
import "../static/fonts/stylesheet.css"

// images
import heroLogo from "../images/pharmachic-neu.png"
import imgSectionTwo from "../images/Expressive-Icons-Section.webp"
import imgSectionFour from "../images/what-makes-us-different.webp"
import ContactImage from "../images/Contact-Us.webp"

// brand images
import brand from "../images/logos pharmachic/Brand Logo.png"
import brand1 from "../images/logos pharmachic/Brand Logo-1.png"
import brand2 from "../images/logos pharmachic/Brand Logo-2.png"
import brand3 from "../images/logos pharmachic/Brand Logo-3.png"
import brand4 from "../images/logos pharmachic/Brand Logo-4.png"
import brand5 from "../images/logos pharmachic/Brand Logo-5.png"
import brand6 from "../images/logos pharmachic/Brand Logo-6.png"
import brand7 from "../images/logos pharmachic/Brand Logo-7.png"
import brand8 from "../images/logos pharmachic/Brand Logo-8.png"
import brand9 from "../images/logos pharmachic/Brand Logo-9.png"
import brand10 from "../images/logos pharmachic/Brand Logo-10.png"
import brand11 from "../images/logos pharmachic/Brand Logo-11.png"
import brand12 from "../images/logos pharmachic/Brand Logo-12.png"
import brand13 from "../images/logos pharmachic/Brand Logo-13.png"
import brand14 from "../images/logos pharmachic/Brand Logo-14.png"
import brand15 from "../images/logos pharmachic/Brand Logo-15.png"
import brand16 from "../images/logos pharmachic/Brand Logo-16.png"
import brand17 from "../images/logos pharmachic/Brand Logo-17.png"
import brand18 from "../images/logos pharmachic/Brand Logo-18.png"
import brand19 from "../images/logos pharmachic/Brand Logo-19.png"
import brand20 from "../images/logos pharmachic/Brand Logo-20.png"
import brand21 from "../images/logos pharmachic/Brand Logo-21.png"
import brand22 from "../images/logos pharmachic/Brand Logo-22.png"
import brand23 from "../images/logos pharmachic/Brand Logo-23.png"
import brand24 from "../images/logos pharmachic/Brand Logo-24.png"
import brand25 from "../images/logos pharmachic/Brand Logo-25.png"
import brand26 from "../images/logos pharmachic/Brand Logo-26.png"
import brand27 from "../images/logos pharmachic/Brand Logo-27.png"
import brand28 from "../images/logos pharmachic/Brand Logo-28.png"
import brand29 from "../images/logos pharmachic/Brand Logo-29.png"
import brand30 from "../images/logos pharmachic/Brand Logo-30.png"
import brand31 from "../images/logos pharmachic/Brand Logo-31.png"
import brand32 from "../images/logos pharmachic/Brand Logo-32.png"
import brand33 from "../images/logos pharmachic/Brand Logo-33.png"
import brand34 from "../images/logos pharmachic/Brand Logo-34.png"
import brand35 from "../images/logos pharmachic/Brand Logo-35.png"
import brand36 from "../images/logos pharmachic/Brand Logo-36.png"
import brand37 from "../images/logos pharmachic/Brand Logo-37.png"
import brand38 from "../images/logos pharmachic/Brand Logo-38.png"

const IndexPage = () => {


const [state, setState] = useState()

const handleChange = (e) => {
  setState({ ...state, [e.target.name]: e.target.value })
}

const handleSubmit = (e) => {
  e.preventDefault()
  const form = e.target
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...state,
    }),
  })
    .then(() => navigate(form.getAttribute('action')))
    .catch((error) => alert(error))
}

  return (
    <>
    <main>
      <title>Welcome to Pharmachic - Official Website</title>
      <Header/>
      <PageTransition
      transitionTime={1000}
    >
      <Section additionalSectionClass="section-offset-bottom">
        <Container>
          <Row>
            <Col firstCol={'lg-6'} responsiveColClass={'col-xs-11'} additionalColClass={'section-first-left'}>
            <span className={'section-text text-upper'}>Pharmachic</span>
            <h1>We Are A Compact Company Specialising In Medical Aesthetic Products.</h1>
            <span className={'section-text'}>Let us help you gain access to the highest quality medical aesthetic products on the market. Our cost-effective intercontinental approach and fast delivery service are what ensures our clients’ satisfaction.</span>
            <div className={'flex-inline center scroll-down'}><h5 className={'mar-r-1'}>Scroll For More</h5><AnchorLink to={`/#about`} className={'button-icon-secondary large radius-round alt'}></AnchorLink></div>
            </Col>
            <Col firstCol={'lg-6'} additionalColClass={'mobile-hide text-center'}>
                <img src={heroLogo} alt={"hero-logo"} style={{marginBottom:`-13px`}}/>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section defaultSectionClass="section-offset-bottom">
        <Container>
          <Row additionalRowClass={'center'}>
            <Col firstCol={'lg-12'} responsiveColClass={'section-second'}>
              <div id={'about'} className={'col-lg-12'} style={{ backgroundImage: `url(${imgSectionTwo})`, backgroundPosition:`center`,backgroundRepeat:`no-repeat`,backgroundSize:`cover`, height:`640px`,display:`flex`,justifyContent:`flex-end`,alignItems:`center`}}>
                  <div className={'col-lg-6 col-sm-12'}>
                    <div className={'row between'}>
                      <div className={'col-lg-6 col-xs-12  section-second-box'}>
                        <i className={'ri-service-line'}></i>
                        <h4>Optimal Product Access</h4>
                      </div>
                      <div className={'col-lg-6 col-xs-12 section-second-box'}>
                        <i className={'ri-checkbox-circle-line'}></i>
                        <h4>Certified Products</h4>
                      </div>
                      <div className={'col-lg-6 col-xs-12  section-second-box'}>
                        <i className={'ri-inbox-unarchive-line'}></i>
                        <h4>Reliable Supply</h4>
                      </div>
                      <div className={'col-lg-6 col-xs-12 section-second-box'}>
                        <i className={'ri-global-line'}></i>
                        <h4>Authorized Registered Distributor</h4>
                      </div>
                    </div>
                  </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <div style={{background:`#C42F46`, position:`relative`, top:`-128px`, zIndex:`-1`}}>
      <Section defaultSectionClass="section-middle">
          <Container>
            <Row additionalRowClass={'center middle end section-third'}>
              <Col firstCol={'lg-6'} responsiveColClass={'col-xs-12'}>
                <h2>Our commitment is to our customers</h2>
              </Col>
              <Col firstCol={'lg-6'} responsiveColClass={'col-xs-12'}>
                <p>Our stores are regularly audited by the Maltese Medicines Authorities and all necessary is done to store the products under the right conditions.</p>

                <p>Our purchasers are passionate about making and maintaining connections with the right partners and finding sources for the right products that our clients needs.</p>
              </Col>
            </Row>
          </Container>
      </Section>
      </div>
      <Section defaultSectionClass="section-offset-top">
        <div  style={{position:`relative`, top:`-200px`, zIndex:`1`}}>
          <Container>
            <Row additionalRowClass={'section-fourth'}>
              <Col firstCol={'lg-6'} responsiveColClass={'col-xs-12'}>
                <img className={'flex mar-auto'} src={imgSectionFour}/>
              </Col>
              <Col firstCol={'lg-6'} responsiveColClass={'col-xs-12'} additionalColClass={'pad-t-5'}>
                <h2>What makes us different</h2>
                <p>As an international medical supplier, we have developed a unique network of partners and suppliers that allows us to provide supply for any client around the world at an internationally competitive cost to their business. Not only can we fill our clients’ orders more affordably, but we also source the same, high quality products that their customers expect.</p>
              </Col>
            </Row>
            <Row additionalRowClass={'middle section-fourth-bottom'}>
              <Col firstCol={'lg-4'} responsiveColClass={'col-xs-8'} additionalColClass={'section-fourth-box'}>
                <span className={'display-4'}>1</span>
                <h3 className={'mar-b-none'}>Personalized Service</h3>
                <p className={'mar-t-none'}>Our knowledgeable and professional team is here to find solutions that contribute to your success and ensure absolute accuracy.</p>
              </Col>
              <Col firstCol={'lg-4'} responsiveColClass={'col-xs-8'}  additionalColClass={'section-fourth-box'}>
                <span className={'display-4'}>2</span>
                <h3 className={'mar-b-none'}>Competitive Pricing</h3>
                <p className={'mar-t-none'}>With our global sourcing strategy, we can bring you competitive pricing and savings on the high quality products that you need.</p>
              </Col>
              <Col firstCol={'lg-4'} responsiveColClass={'col-xs-8'}  additionalColClass={'section-fourth-box'}>
                <span className={'display-4'}>3</span>
                <h3 className={'mar-b-none'}>Global Reach</h3>
                <p className={'mar-t-none'}>We pride ourselves on our ability to reach clients around the world and provide the products and service that they need to grow.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Section>
      <Section defaultSectionClass="section-offset-top pad-b-5">
        <Container>
            <Row additionalRowClass={'section-fifth'}>
              <Col firstCol={'lg-12 text-center'}>
                <h2 id={'brands'} >Available Brands</h2>
              </Col>
            </Row>
            <Row additionalRowClass={'bewteen center pad-t-1 pad-b-2'} >
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand1} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand2} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand3} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand4} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand5} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand6} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand7} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand8} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand9} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand10} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand11} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand12} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand13} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand14} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand15} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand16} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand17} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand18} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand19} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand20} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand21} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand22} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand23} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand24} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand25} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand26} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand27}  alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand28} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand29} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand30} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand31} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand32} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand33} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand34} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand35} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand36} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
                <img src={brand37} alt="brand"/>
              </Col>
              <Col firstCol={'lg-2'} responsiveColClass={'col-md-3 col-sm-4 col-xs-12'} additionalColClass={'text-center'}>
              < img src={brand38} alt="brand"/>
              </Col>
            </Row>
        </Container>
      </Section>
      <div id={'contact'} style={{backgroundImage: `url(${ContactImage})`, backgroundPosition:`left`, backgroundSize:`cover`}}>
      <Section defaultSectionClass="section">
          <Container>
            <Row additionalRowClass={'between middle end'}>
              <Col firstCol={'lg-6'} responsiveColClass={'col-xs-12'}>
                <h2>Contact us today</h2>
                <span className={'section-text'}>For co-operation partner opportunities, please contact us.</span>
              </Col>
              <Col firstCol={'lg-5'} responsiveColClass={'col-xs-12'}>
                <NetlifyForm name='Contact' id={'form'} action='/?success=true' honeypotName='bot-field' data-netlify="true" method="POST" onSubmit={handleSubmit}>
                  {({  success, error }) => (
                    <>
                      <Honeypot />
                      {success && <p>Thanks for contacting us!</p>}
                      {error && (
                        <p>Sorry, we could not reach our servers. Please try again later.</p>
                      )}
                      <div>
                        <label htmlFor='name'>Full Name</label>
                        <input required type='text' name='name' id='name' onChange={handleChange} />
                      </div>
                      <div>
                        <label htmlFor='email'>Email</label>
                        <input required type='email' name='email' id='email' onChange={handleChange} />
                      </div>
                      <div>
                        <label htmlFor='name'>Company</label>
                        <input required type='text' name='name' id='name' onChange={handleChange} />
                      </div>
                      <div>
                        <label htmlFor='message'>Message</label>
                        <textarea required
                          type='text'
                          name='message'
                          id='message'
                          onChange={handleChange}
                        />
                      </div>
                      <div className={'float-right wrap'}>
                        <button type='submit'>Send Message</button>
                      </div>
                    </>
                  )}
                </NetlifyForm>
              </Col>
            </Row>
          </Container>
      </Section>
      </div>
      </PageTransition>
      <Footer/>
    </main>
    </>
  )

}


export default IndexPage
