import * as React from "react"

const Footer = () => {
    return (
    <footer className={"footer"}>
        <div className={"footer-drawer"}>
            <div className={"col-lg-12 footer-drawer-wraper"}>
                <div className={"col-lg-12 col-sm-12 text-center footer-drawer-copyright"}>
                    <span>Pharmachic {new Date().getFullYear()}. All rights reserved{` `}</span>
                </div>
            </div>
        </div>
    </footer>
    )   
}

export default Footer