import * as React from "react"
import PropTypes from "prop-types"



const defaultClass = 'col-'

const Col = ({ firstCol, responsiveColClass, additionalColClass, children }) => {

  return (
    <>
        <div className={defaultClass + firstCol + ' ' + responsiveColClass + ' ' + additionalColClass}>{children}</div>

    </>
  )
  
}

Col.propTypes = {
  children: PropTypes.node.isRequired,
  firstCol: PropTypes.string.isRequired,
  responsiveCol: PropTypes.string,
  additionalColClass: PropTypes.string,
}
Col.defaultProps = {
  responsiveColClass:``,
  additionalColClass:``,
}


export default Col
