import * as React from "react"
import { Link, navigate } from "gatsby"
import LogoImage from "../images/pharmachic-logo.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const NavbarMobile = () => {


  return (

    <nav className={"navigation mobile-show"}>
      <ul className={"navigation__list"}>
        <li>
        <Link to="/" className={'float-left flex'} style={{marginTop:`8px`}}>
          <img  src={LogoImage} alt="Logo SOSIVIO" />
        </Link>
        <AnchorLink to={`/#contact`} className={'button-primary large radius-round float-right'}>Contact Us</AnchorLink>
        </li>
      </ul>
    </nav>

  )
    
}

export default NavbarMobile