
import * as React from "react"
import PropTypes from "prop-types"




const Section = ({ defaultSectionClass,additionalSectionClass, children }) => {

  return (
    <>
      <div className={defaultSectionClass  +' '+ additionalSectionClass}>
          {children}
      </div>
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  defaultSectionClass: PropTypes.string,
  additionalSectionClass: PropTypes.string,
}
Section.defaultProps = {
  additionalSectionClass:``,
  defaultSectionClass: `section`,
}
export default Section