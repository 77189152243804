import * as React from "react"
import { Link, navigate } from "gatsby"
import LogoImage from "../images/pharmachic-logo.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Navbar = () => {


  return (

    <nav className={"navigation mobile-hide"}>
    <Link to="/">
      <img  src={LogoImage} style={{width:`100%`}} alt="Logo SOSIVIO" />
    </Link>
    <ul className={"navigation__list"}>

        <li>
          <AnchorLink to={`/#about`}>About</AnchorLink>
        </li>
        <li>
            <AnchorLink to={`/#brands`}>Brands</AnchorLink>
        </li>
        <li>
            <AnchorLink to={`/#contact`} className={'button-primary large radius-round'}>Contact Us</AnchorLink>
        </li>
    </ul>
    </nav>
  )
    
}

export default Navbar