import * as React from "react"
import PropTypes from "prop-types"



const defaultClass = 'row'

const Row = ({ additionalRowClass, children }) => {

  return (
    <>
        <div className={defaultClass +' '+ additionalRowClass}>{children}</div>

    </>
  )
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  additionalRowClass: PropTypes.string,

}
Row.defaultProps = {
  additionalRowClass:``,
}


export default Row

