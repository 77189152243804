
import * as React from "react"
import PropTypes from "prop-types"




const Container = ({ defaultContainerClass,children }) => {

  return (
    <>
      <div className={defaultContainerClass}>
        {children}
      </div>
    </>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  defaultContainerClass: PropTypes.string,
}
Container.defaultProps = {
  defaultContainerClass: `container`,
}
export default Container